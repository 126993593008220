export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/account": [~4],
		"/account/home": [~5],
		"/account/home/basicinfo/[appId]": [~6],
		"/account/home/documents/[appId]": [~7],
		"/account/home/documents/[appId]/[id]": [~8],
		"/account/home/statements/[appId]": [~9],
		"/blog": [~10],
		"/blog/helper": [12],
		"/blog/search": [~13],
		"/blog/tag/[id]": [~14],
		"/blog/[id]": [~11],
		"/caring": [15],
		"/companion": [16],
		"/debug": [~17],
		"/debug/cache": [~18],
		"/delivery/[id]": [~19],
		"/information/explain-direct-cremation-choice-family": [20],
		"/information/how-to-talk-parents-about-funeral-arrangements-preplanning": [21],
		"/memorials": [~22],
		"/memorials/admin": [23],
		"/memorials/all": [~24],
		"/memorials/condolences-writing-tool": [25],
		"/memorials/create": [26],
		"/memorials/new-password": [27],
		"/memorials/obituary-writing-tool": [28],
		"/memorials/page/[url]": [~29],
		"/memorials/page/[url]/edit": [~30],
		"/memorials/page/[url]/video": [~31],
		"/memorials/reset-password": [32],
		"/press": [33],
		"/resources/cemeteries": [34],
		"/resources/cemeteries/[province]": [35],
		"/resources/cemeteries/[province]/[id]": [36],
		"/resources/repatriate": [37],
		"/start/[shared]": [~38],
		"/status/[applicationId]": [~39],
		"/two": [~40],
		"/two/results": [41],
		"/urns": [~42],
		"/urns/blogs": [~43],
		"/urns/blogs/[bloghandle]/[handle]": [~44],
		"/urns/collections/[collection]": [~45],
		"/urns/collections/[collection]/products/[handle]": [~46],
		"/urns/ornaments": [~47],
		"/urns/pages/[page]": [~48],
		"/urns/policies/refund-policy": [49],
		"/urns/policies/shipping-policy": [50],
		"/urns/search": [~51],
		"/[...catchall]": [~3]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';